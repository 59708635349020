<template>
  <div class="cateHome">
    <el-form :model="queryParams" ref="queryForm" :inline="true" v-show="showSearch" label-width="68px"> </el-form>

    <!-- <el-row :gutter="10" class="mb8" style="margin-top: 20px; margin-bottom: 20px">
          <el-col :span="1.5">
        <el-button type="primary" plain icon="el-icon-plus" size="mini" @click="handleAdd">新增</el-button>
      </el-col>
      <el-col :span="1.5">
        <el-button type="info" plain icon="el-icon-download" size="mini" @click="handleExport"
          v-hasPermi="['image:biologicalCategory:export']">导出</el-button>
      </el-col>

      <el-col :span="1.5">
        <el-upload class="upload-demo" :action="$uploadUrl + '/image/biologicalCategory/importCategoryInfo'"
          :headers="token" :on-success="handleSuccess" :show-file-list="false">
          <el-button type="warning" plain icon="el-icon-upload2" size="mini"
            v-hasPermi="['image:biologicalCategory:export']">导入</el-button>
        </el-upload>
      </el-col>
      <el-col :span="1.5">
        <el-button type="warning" plain icon="el-icon-download" size="mini" @click="handleDownload"
          v-hasPermi="['image:biologicalCategory:export']">下载模板</el-button>
      </el-col>

    </el-row> -->

    <el-row :gutter="10" class="mb8" style="margin-top: 30px; margin-bottom: 20px">
      <el-col :span="16" style="display: flex;margin-bottom: 10px;" v-for="(item, index) in selectNumber" :key="index">

        <el-select v-model="item.id" placeholder="请选择" style="margin:0 30px;width: 150px;" @change="selectchange">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
        <el-input v-model="item.val" style="margin-right:30px;width: 150px;caret-color: black;" @input="selinput"
          placeholder="请输入"></el-input>

        <el-button type="primary" size="mini" @click="addselect" v-if="index + 1 == 1">新增</el-button>
        <el-button type="danger" size="mini" @click="remoselect" v-if="index + 1 > 1">删除</el-button>
        <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery"
          v-if="index + 1 == selectNumber.length">搜索</el-button>
        <el-button type="primary" size="mini" @click="init" v-if="index + 1 == selectNumber.length">返回全部</el-button>
      </el-col>
    </el-row>

    <!-- <div class="flexRight">
      <div>
        <span>{{'大类或纲：'}}{{ quantity.classNum }}</span>
      </div>

      <div>
        <span>{{'属：' }}{{ quantity.genusNum }}</span>
      </div>

      <div>
        <span>{{ '种：'}}{{ quantity.speciesNum }}</span>
      </div>
    </div> -->

    <!-- 正式版 -->
    <el-tree :data="biologicalCategoryList" :props="defaultProps" class="tree-line" :key="keyindex"
      icon-class="el-icon-d-arrow-right" :indent="0" node-key="biologicalCategoryId" :expand-on-click-node="true"
      ref="tree" :default-expand-all="showflag" :lazy="lazyflag" :load="getchildrenList" @node-collapse="closeNode"
      @node-expand="openNode">
      <span class="custom-tree-node" slot-scope="{ node, data }">
        <!-- <span v-if="data.categoryLevel == 14 && $i18n.locale == 'en-us'"
          style="font-style: italic; border-bottom: 1px solid #000">
          <span>{{ data.categoryName || '' }}
            <span style="marginleft: 10px"></span>
            {{ data.categoryNameEn || '' }}
          </span>
          <span style="marginleft: 10px"></span>
          <span>{{ data.nomenclatorEn || '' }}</span>
          <span style="margin-right: 10px"></span>

          <span>{{ setTime(data.defineTime) || '' }}</span>
          <span>
            <span style="margin-right: 10px"></span>
            <el-button type="text" size="mini" @click="() => addCate(node, data)" icon="el-icon-folder-add">新增
            </el-button>
            <el-button type="text" size="mini" @click="() => addSonCate(node, data)" v-if="data.categoryLevel != 14"
              icon="el-icon-document-add">新增子类</el-button>
            <el-button type="text" size="mini" @click="() => lookInfo(node, data)" icon="el-icon-info">查看详情</el-button>
          </span>
        </span> -->
        <span>
          <span style="border-bottom: 1px solid #000">
            <span
              :style="{ 'font-style': data.categoryLevel == 14 ? 'oblique' : 'initial', 'color': data.categoryLevel == 14 ? '#000000' : '#606266', }">{{
                (data.categoryName + (data.categoryLevel == 13 ? '属' : '')) || '' }}
              <span style="marginleft: 10px"></span>
              <span
                :style="{ 'font-style': data.categoryLevel == 14 ? 'oblique' : 'initial', 'color': data.categoryLevel == 14 ? '#000000' : '#606266', }">{{
                  data.categoryNameEn || '' }}</span>

            </span>
            <span style="marginleft: 10px"></span>
            <span>{{ data.nomenclatorEn || '' }}</span>
            <span style="margin-right: 10px"></span>

            <span>{{ setTime(data.defineTime) || '' }}</span>
          </span>

          <span>
            <span style="margin-right: 10px"></span>
            <el-button type="text" size="mini" @click="() => addCate(node, data)" icon="el-icon-folder-add">新增
            </el-button>
            <el-button type="text" size="mini" @click="() => addSonCate(node, data)" v-if="data.categoryLevel != 14"
              icon="el-icon-document-add">新增子类</el-button>

            <el-button type="text" size="mini" @click="() => lookInfo(node, data)" icon="el-icon-info">查看详情</el-button>
          </span>
        </span>
      </span>
    </el-tree>

    <!-- 测试版 -->
    <!-- <el-tree :data="biologicalCategoryList" :props="defaultProps" class="tree-line"
      icon-class="el-icon-circle-plus-outline" :indent="0" node-key="biologicalCategoryId" :expand-on-click-node="false"
      ref="tree" :default-expand-all="true">
      <span class="custom-tree-node" slot-scope="{ node, data }">
        <span v-if="data.categoryLevel == 14 && $i18n.locale == 'en-us'"
          style="font-style: italic; border-bottom: 1px solid #000">
          <span>{{ data.categoryName || '' }}
            <span style="marginleft: 10px"></span>
            {{ data.categoryNameEn || '' }}
          </span>
          <span style="marginleft: 10px"></span>
          <span>{{ data.nomenclatorEn || '' }}</span>
          <span style="margin-right: 10px"></span>

          <span>{{ setTime(data.defineTime) || '' }}</span>
          <span>
            <span style="margin-right: 10px"></span>
            <el-button type="text" size="mini" @click="() => addCate(node, data)" icon="el-icon-folder-add">新增
            </el-button>
            <el-button type="text" size="mini" @click="() => addSonCate(node, data)" v-if="data.categoryLevel != 14"
              icon="el-icon-document-add">新增子类</el-button>
            <el-button type="text" size="mini" @click="() => lookInfo(node, data)" icon="el-icon-info">查看详情</el-button>
          </span>
        </span>
        <span v-else>
          <span style="border-bottom: 1px solid #000">
            <span>{{ data.categoryName || '' }}
              <span style="marginleft: 10px"></span>
              {{ data.categoryNameEn || '' }}
            </span>
            <span style="marginleft: 10px"></span>
            <span>{{ data.nomenclatorEn || '' }}</span>
            <span style="margin-right: 10px"></span>

            <span>{{ setTime(data.defineTime) || '' }}</span>
          </span>

          <span>
            <span style="margin-right: 10px"></span>
            <el-button type="text" size="mini" @click="() => addCate(node, data)" icon="el-icon-folder-add">新增
            </el-button>
            <el-button type="text" size="mini" @click="() => addSonCate(node, data)" v-if="data.categoryLevel != 14"
              icon="el-icon-document-add">新增子类</el-button>

            <el-button type="text" size="mini" @click="() => lookInfo(node, data)" icon="el-icon-info">查看详情</el-button>
          </span>
        </span>
      </span>
    </el-tree> -->

    <!-- <pagination v-show="total > 0" :total="total" :page.sync="queryParams.pageNum" :limit.sync="queryParams.pageSize" @pagination="getList" /> -->

    <!-- 添加或修改生物分类对话框 -->
    <el-dialog :title="title" :visible.sync="open" width="1000px" append-to-body>
      <el-button type="text" size="mini" @click="() => editCate(null, form)" icon="el-icon-edit" v-if="editFlag">修改
      </el-button>

      <el-button type="text" size="mini" @click="() => remove(null, form)" icon="el-icon-delete" v-if="editFlag">删除
      </el-button>

      <el-form ref="form" :model="form" :rules="rules" label-width="150px" :disabled="lookFlag" class="bform"
        style="display: flex; flex-wrap: wrap">
        <el-form-item label="分类名称" prop="categoryName">
          <el-input v-model="form.categoryName" placeholder="请输入分类名称" />
        </el-form-item>
        <el-form-item label="分类名称-拉丁文" prop="categoryNameEn">
          <el-input v-model="form.categoryNameEn" placeholder="请输入分类名称-拉丁文" />
        </el-form-item>
        <el-form-item label="上级菜单" v-if="!lookFlag">
          <treeselect :key="form.parentId" :disabled="treedisabled" v-model="form.parentId" @select="changeSelect"
            :options="menuOptions" placeholder="选择上级菜单" :load-options="loadOptions" />
          <!-- <treeselect class="tree" :alwaysOpen="true" :multiple="true" :options="menuOptions" :load-options="loadOptions" v-model="values" @select="handleNodeClick"  @deselect="setClear" placeholder="选择上级菜单" noChildrenText="没有更多了"/> -->
        </el-form-item>
        <!--   <el-form-item label="父级分类名称" prop="parentCategoryName" v-if="form.categoryLevel == 14">
          <el-input v-model="form.parentCategoryName" placeholder="请输入父级分类名称" />
        </el-form-item> -->
        <!-- <el-form-item label="父级分类名称-拉丁文" prop="parentCategoryNameEn">
          <el-input v-model="form.parentCategoryNameEn" placeholder="请输入父级分类名称-拉丁文" />
        </el-form-item> -->
        <el-form-item label="文献" prop="input" v-if="form.categoryLevel == 14">
          <el-input v-model="form.reference" placeholder="请输入文献" />
        </el-form-item>
        <el-form-item label="文献DOI" prop="input" v-if="form.categoryLevel == 14">
          <el-input v-model="form.doi" placeholder="请输入文献DOI" />
        </el-form-item>
        <el-form-item label="命名人-拉丁文" prop="nomenclatorEn">
          <el-input v-model="form.nomenclatorEn" placeholder="请输入命名人-拉丁文" />
        </el-form-item>
        <el-form-item label="定义时间" prop="defineTime">
          <el-date-picker clearable size="small" v-model="form.defineTime" value-format="yyyy-MM-dd HH:mm:ss" type="year"
            placeholder="选择上传时间">
          </el-date-picker>
        </el-form-item>
        <!-- <el-form-item label="作者" prop="defineTime">
          <el-input v-model="form.defineTime" placeholder="请输入命名人-拉丁文" />
        </el-form-item> -->
        <div v-if="form.categoryLevel == 14" style="display: flex; flex-wrap: wrap">
          <el-form-item label="修订属种" prop="revisedSpecies">
            <el-input v-model="form.revisedSpecies" placeholder="请输入修订属种" />
          </el-form-item>
          <el-form-item label="修订属种-拉丁文" prop="revisedSpeciesEn">
            <el-input v-model="form.revisedSpeciesEn" placeholder="请输入修订属种-拉丁文" />
          </el-form-item>
          <el-form-item label="岩性" prop="lithostratigraphy">
            <el-input v-model="form.lithostratigraphy" placeholder="请输入岩性" />
          </el-form-item>
          <el-form-item label="岩性-拉丁文" prop="lithostratigraphyEn">
            <el-input v-model="form.lithostratigraphyEn" placeholder="请输入岩性-拉丁文" />
          </el-form-item>
          <el-form-item label="时代-宙" prop="eon">
            <el-input v-model="form.eon" placeholder="请输入时代-宙" />
          </el-form-item>
          <el-form-item label="时代-代" prop="era">
            <el-input v-model="form.era" placeholder="请输入时代-代" />
          </el-form-item>
          <el-form-item label="时代-纪" prop="period">
            <el-input v-model="form.period" placeholder="请输入时代-纪" />
          </el-form-item>
          <el-form-item label="时代-世" prop="epoch">
            <el-input v-model="form.epoch" placeholder="请输入时代-世" />
          </el-form-item>
          <el-form-item label="时代-期" prop="age">
            <el-input v-model="form.age" placeholder="请输入时代-期" />
          </el-form-item>
          <el-form-item label="时代-拉丁文" prop="ageEn">
            <el-input v-model="form.ageEn" placeholder="请输入时代-拉丁文" />
          </el-form-item>
          <el-form-item label="首现年龄/Ma" prop="ageFrom">
            <el-input v-model="form.ageFrom" placeholder="请输入首现年龄/Ma" />
          </el-form-item>
          <el-form-item label="末现年龄/Ma" prop="ageTo">
            <el-input v-model="form.ageTo" placeholder="请输入末现年龄/Ma" />
          </el-form-item>
          <el-form-item label="平均年龄/Ma" prop="ageMid">
            <el-input v-model="form.ageMid" placeholder="请输入平均年龄/Ma" />
          </el-form-item>
          <el-form-item label="录入员" prop="input">
            <el-input v-model="form.input" placeholder="请输入录入员" />
          </el-form-item>
          <el-form-item label="校对员" prop="checkA">
            <el-input v-model="form.checkA" placeholder="请输入校对员" />
          </el-form-item>
        </div>
      </el-form>
      <div slot="footer" class="dialog-footer" v-if="!lookFlag">
        <el-button type="primary" @click="submitForm">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getToken } from '@/utils/auth';
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import imageApi from '@/api/image/index';
import { handleTree } from '@/utils/index';
import { LOAD_CHILDREN_OPTIONS } from '@riophae/vue-treeselect';
export default {
  name: 'BiologicalCategory',
  components: { Treeselect },
  data () {
    return {
      showflag: false,
      lazyflag: true,
      keyindex: 1,
      selectNumber: [{
        id: "",
        val: ""
      }],
      quantity: {},
      defaultProps: {
        children: 'children',
        label: 'name',
      },
      searchValue: '',
      categoryLevel: '',
      lookFlag: false,
      ipt: false,
      // 当前选择的分类ID
      currentId: null,
      // 菜单树选项
      menuOptions: [],
      // 是否展开，默认全部折叠
      isExpandAll: true,
      // 遮罩层
      loading: true,
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 显示搜索条件
      showSearch: true,
      // 是否可以下拉加载
      pullflag: false,
      // 第一次获取全部
      allflag: true,
      // 分类页数
      classifypageNum: 1,
      // 数据条数
      classifypageSize: 5,
      // 总条数
      total: 0,
      values: '',
      // 生物分类表格数据
      biologicalCategoryList: [],
      // 弹出层标题
      title: '',
      // 是否显示弹出层
      open: false,
      // 保存的节点
      treeNode: null,
      // 保存的resolve
      resolve: null,
      // 当前子节点列表
      currentchilds: [],
      parentId: null,
      treedisabled: false,
      // 查询参数
      queryParams: {
        // pageNum: 10,
        // pageSize: 1,
        categoryName: null,
        categoryNameEn: null,
        categoryLevel: null,
        parentId: null,
        parentCategoryName: null,
        parentCategoryNameEn: null,
        nomenclatorEn: null,
        defineTime: null,
        revisedSpecies: null,
        revisedSpeciesEn: null,
        lithostratigraphy: null,
        lithostratigraphyEn: null,
        eon: null,
        era: null,
        period: null,
        epoch: null,
        age: null,
        ageEn: null,
        ageFrom: null,
        ageTo: null,
        ageMid: null,
        input: null,
        checkA: null,
      },
      // 表单参数
      form: {},
      // 表单校验
      rules: {},
      token: '',
      editFlag: false,
      options: [
        {
          value: '1',
          label: '门/纲'
        }, {
          value: '2',
          label: '门'
        }, {
          value: '3',
          label: '纲'
        }, {
          value: '4',
          label: '亚纲'
        }, {
          value: '5',
          label: '次纲'
        }, {
          value: '6',
          label: '超目'
        }, {
          value: '7',
          label: '目'
        }, {
          value: '8',
          label: '亚目'
        }, {
          value: '9',
          label: '次目'
        }, {
          value: '10',
          label: '超科'
        }, {
          value: '11',
          label: '科'
        }, {
          value: '12',
          label: '亚科'
        }, {
          value: '13',
          label: '属'
        }, {
          value: '14',
          label: '种名'
        }
      ]
    };
  },
  created () {
    this.token = {
      Authorization: getToken(),
    };
    imageApi.dataStatistics().then((res) => {
      this.quantity = res.data;
    });
    // this.getList();
    this.getTreeselect();

    this.init()
  },
  mounted () {
    window.onscroll = () => {

      if (!this.pullflag) {
        return true;
      }
      //变量scrollTop是滚动条滚动时，距离顶部的距离
      var scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      //变量windowHeight是可视区的高度
      var windowHeight = document.documentElement.clientHeight || document.body.clientHeight;
      //变量scrollHeight是滚动条的总高度
      var scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
      //滚动条到底部的条件(距底部20px时触发加载)
      if (scrollTop + windowHeight >= scrollHeight && !this.isAchiveBottom && !this.noMore) {
        this.isAchiveBottom = true;
        //延时触发数据加载

        setTimeout(() => {
          imageApi.getchildrenList({
            parentId: this.currentId,
            pageNum: this.classifypageNum,
            pageSize: this.classifypageSize
          }).then((response) => {


            response.rows.map((val) => {
              this.$refs.tree.append(val, this.treeNode)
            })
            setTimeout(() => {
              this.isAchiveBottom = false;
            }, 0)

            // 对是否可以再次进行拉取数据做处理
            if (response.rows.length >= this.classifypageSize) {
              this.classifypageNum++;
              this.pullflag = true;
            } else {
              this.classifypageNum = 1;
              this.pullflag = false;
            }
          })
        }, 1500);
      } else {
        // this.$refs.waterfall.waterfallOver();
      }
    };
  },
  methods: {
    setTime (val) {
      if (val) {
        return val.slice(0, 4);
      } else {
        return val;
      }
    },
    reset () {
      this.form = {};
    },
    selectchange (e) {
      this.categoryLevel = e
    },
    selinput (e) {
      this.searchValue = e
    },
    /** 新增按钮操作 */
    handleAdd () {
      this.lookFlag = false;
      this.reset();
      this.open = true;
      this.getTreeselect();
      this.title = '添加生物分类';
    },
    addCate (node, data) {
      this.treedisabled = false;
      this.lookFlag = false;
      this.reset();
      this.open = true;
      this.form.categoryLevel = data.categoryLevel;
      this.title = '添加生物分类';
    },
    editCate (node, data) {
      this.lookFlag = false;
      this.reset();
      this.open = true;
      this.form = data;
      this.title = '修改生物分类';
    },
    lookInfo (node, data) {
      this.lookFlag = true;
      this.editFlag = true;
      this.form = data;
      this.open = true;
    },
    addselect () {
      this.selectNumber.push({
        id: "",
        val: ""
      })
    },
    remoselect () {
      this.selectNumber.pop()
    },
    // 分类层级（1大类-对应类别门或纲 2门 3纲 4亚纲 5次纲 6超目 7目 8亚目 9次目 10超科 11科 12亚科 13属 14种名） */
    setName (val) {
      let str = '';
      switch (val) {
        case 1:
          str = '大类-对应类别门或纲';
          break;
        case '2':
          str = '门';
          break;
        case '3':
          str = '纲';
          break;
        case ' 4':
          str = '亚纲';
          break;
        case '5':
          str = '次纲';
          break;
        case '6':
          str = '超目';
          break;
        case '7':
          str = '目';
          break;
        case '8':
          str = '亚目';
          break;
        case '9':
          str = '次目';
          break;
        case '10':
          str = '超科';
          break;
        case '11':
          str = '科';
          break;
        case '12':
          str = '亚科';
          break;
        case '13':
          str = '属';
          break;
        case '14':
          str = '种名';
          break;
      }
      return str;
    },
    addSonCate (node, data) {
      this.treedisabled = true;
      this.lookFlag = false;
      this.reset();
      this.open = true;

      if (this.form.categoryLevel != 14) {
        this.form.categoryLevel = JSON.parse(JSON.stringify(parseInt(data.categoryLevel))) + 1;
      } else {
        this.form.categoryLevel = data.categoryLevel;
      }

      this.form.parentId = node.data.categoryName;
      this.parentId = node.data.biologicalCategoryId

      this.title = '添加生物分类';
    },
    changeSelect (e) {
      if (e.biologicalCategoryId == 0) {
        this.form.categoryLevel = 2;
      }
      this.form.parentCategoryLevel = e.categoryLevel;
      // this.form.categoryLevel = e.categoryLevel;
    },
    handleSuccess (res) {
      if (res.code == 200) {
        this.$modal.msgSuccess('导入成功');
      }
    },
    handleDownload () {
      // /dev-api/image/biologicalCategoryInfo/importTemplate
      this.download('/image/biologicalCategory/importTemplate', '', '模板.xlsx');
    },
    /** 转换菜单数据结构 */
    normalizer (node) {
      if (node.children && !node.children.length) {
        delete node.children;
      }
      return {
        id: node.biologicalCategoryId,
        label: node.categoryName || node.categoryNameEn,
        children: node.children,
      };
    },
    /** 查询菜单下拉树结构 */
    getTreeselect () {
      imageApi.listBiologicalCategory({ categoryLevel: 2 }).then((response) => {
        let data = handleTree(response.rows, 'biologicalCategoryId');
        data = this.removeDuplicateObj(data);
        this.menuOptions = data.map((el) => {
          return {
            id: el.biologicalCategoryId,
            label: el.categoryName || el.categoryNameEn,
            children: null
          };
        });
      });
    },
    loadOptions ({ action, parentNode, callback }) {
      // Typically, do the AJAX stuff here.
      // Once the server has responded,
      // assign children options to the parent node & call the callback.
      if (action === LOAD_CHILDREN_OPTIONS) {
        //加载点击节点的 子节点
        imageApi
          .getchildrenList({
            parentId: parentNode.id
          })
          .then((response) => {
            let arr = [];
            arr = response.rows.map((el) => {
              return {
                id: el.biologicalCategoryId,
                label: el.categoryName || el.categoryNameEn,
                children: null
              };
            });
            parentNode.children = arr;
          });
        callback();
      }
    },
    // 初始获取分类数据
    init () {
      this.loading = true;
      this.showflag = false;
      this.lazyflag = true;
      this.keyindex++;

      imageApi.listBiologicalCategory({ categoryLevel: 2 }).then((response) => {
        let data = handleTree(response.rows, 'biologicalCategoryId');
        data = this.removeDuplicateObj(data)
        this.biologicalCategoryList = data
        this.total = response.total;
        this.loading = false;
      });
    },

    /** 查询生物分类列表 */
    async getList () {
      this.loading = true;
      this.showflag = true
      this.lazyflag = false
      this.keyindex++
      let Proall = []
      let categoryLevel = this.categoryLevel
      imageApi.listBiologicalCategory({ categoryName: this.searchValue.trim(), categoryLevel }).then((response) => {

        response.rows.filter((val) => {
          Proall.push(imageApi.parentListBiologicalCategory(Number(val.biologicalCategoryId)))
        })
        Promise.all(Proall).then(res => {
          let dataList = []
          res.forEach(val => {
            dataList = dataList.concat(val.rows)
          })

          this.biologicalCategoryList = handleTree(dataList, 'biologicalCategoryId');
          this.biologicalCategoryList = this.noRepeat(this.biologicalCategoryList)
        })


        // this.biologicalCategoryList = handleTree(response.rows, 'biologicalCategoryId');
        this.total = response.total;
        this.loading = false;
      });
    },
    // 获取当前分类的子分类
    getchildrenList (data, resolve) {
      this.classifypageNum = 1;
      // 记录当前选择的Id
      this.currentId = data.data.biologicalCategoryId
      this.loading = true;
      if (this.allflag) {
        this.allflag = false
        imageApi.getchildrenList({}).then((response) => {
          // if (response.rows.length >= this.classifypageSize) {
          //   this.classifypageNum++;
          //   this.pullflag = true;
          // } else {
          //   this.classifypageNum = 1;
          //   this.pullflag = false;
          // }
          resolve(response.rows)
          this.treeNode = data;
        })
      }
      else {
        // this.allflag = false
        imageApi.getchildrenList({
          parentId: data.data.biologicalCategoryId,
          pageNum: this.classifypageNum,
          // pageSize: this.classifypageSize
        }).then((response) => {
          if (response.rows.length >= this.classifypageSize) {
            this.classifypageNum++;
            this.pullflag = true;
          } else {
            this.classifypageNum = 1;
            this.pullflag = false;
          }
          resolve(response.rows)
          this.treeNode = data;
        })
      }

    },
    // 取消按钮
    cancel () {
      this.open = false;
      this.reset();
    },
    // 关闭子节点
    closeNode (a, Node, c) {
      this.treeNode = Node;
      if (Node.childNodes.length % this.classifypageSize == 0 && Node.childNodes.length != 0) {
        this.pullflag = true
        this.classifypageNum = parseInt(Node.childNodes.length / this.classifypageSize) + 1
      }
      else {
        this.pullflag = false
      }
    },
    openNode (a, Node, c) {
      this.treeNode = Node;
      if (Node.childNodes.length % this.classifypageSize == 0 && Node.childNodes.length != 0) {
        this.pullflag = true
        this.classifypageNum = parseInt(Node.childNodes.length / this.classifypageSize) + 1
      }
      else {
        this.pullflag = false
      }
    },

    /** 搜索按钮操作 */
    handleQuery () {
      this.getList();
    },

    // 多选框选中数据
    handleSelectionChange (selection) {
      this.ids = selection.map((item) => item.biologicalCategoryId);
      this.single = selection.length !== 1;
      this.multiple = !selection.length;
    },

    /** 修改按钮操作 */
    handleUpdate (row) {
      this.reset();
      const biologicalCategoryId = row.biologicalCategoryId || this.ids;
      getBiologicalCategory(biologicalCategoryId).then((response) => {
        this.form = response.data;
        this.open = true;
        this.title = '修改生物分类';
      });
    },
    /** 提交按钮 */
    submitForm () {
      if (this.parentId) {
        this.form.parentId = this.parentId
      }
      this.$refs['form'].validate((valid) => {
        if (valid) {
          if (this.form.biologicalCategoryId != null) {
            imageApi.updateBiologicalCategory(this.form).then((response) => {
              this.$message({
                message: '修改成功',
                type: 'success',
              });
              this.open = false;
              this.init();
              this.editFlag = false;
              this.parentId = null
            });
          } else {
            imageApi.addBiologicalCategory(this.form).then((response) => {
              this.editFlag = false;
              this.$message({
                message: '新增成功',
                type: 'success',
              });
              this.open = false;
              this.parentId = null
              this.init();
            });
          }
        }
      });
    },
    /** 删除按钮操作 */
    remove (node, row) {
      const biologicalCategoryIds = row.biologicalCategoryId;
      this.$confirm('是否确认删除生物分类编号为"' + biologicalCategoryIds + '"的数据项？')
        .then(function () {
          return imageApi.delBiologicalCategory(biologicalCategoryIds);
        })
        .then(() => {
          this.getList();
          this.open = false;
          this.getList();
          this.editFlag = false;
          this.$modal.msgSuccess('删除成功');
        })
        .catch(() => { });
    },
    noRepeat (data) {
      const newdata = data.map(item => (JSON.stringify(item)))
      let unique = [...new Set(newdata)].map(item => (JSON.parse(item)))
      unique.filter(val => {
        if (val.children) {
          val.children = this.noRepeat(val.children)
        }
      })
      return unique
    },
    removeDuplicateObj (arr) {
      let obj = {};
      arr = arr.reduce((newArr, next) => {
        obj[next.biologicalCategoryId] ? "" : (obj[next.biologicalCategoryId] = true && newArr.push(next));
        return newArr;
      }, []);
      return arr;
    },
    /** 导出按钮操作 */
    handleExport () {
      this.download(
        "image/biologicalCategory/export",
        {
          ...this.queryParams,
        },
        `biologicalCategory_${new Date().getTime()}.xlsx`
      );
    },
    async handleNodeClick (e) {
      // this.SET_TAG({
      //   name: e.label,
      //   type: 'category'
      // });
      // let str = this.getCondition.searchValue;
      // this.SET_SEARCH({
      //   searchVal: str,
      //   range: 'info',
      //   from: 1
      // });
      // const { data: res } = await commonApi.search(this.getCondition);
      // this.SET_PAGE_LIST(res.rowsMap);
      // this.SET_SEARCH({
      //   searchVal: this.searchVal,
      //   range: 'info',
      //   from: res.from
      // });
    },
    async setClear (node) {
      // let find = this.getTag.findIndex((el) => {
      //   return el.name == node.label;
      // });
      // if (find != -1) {
      //   this.DEL_TAG(find);
      //   let str = this.getCondition.searchValue;
      //   this.SET_SEARCH({
      //     searchVal: str,
      //     range: '*',
      //     from: 1
      //   });
      //   const { data: res } = await commonApi.search(this.getCondition);
      //   this.SET_PAGE_LIST(res.rowsMap);
      // }
    },
  },
};
</script>
<style scoped lang="scss">
// ::v-deep .el-input {
//   width: 40px;
//   height: 40px;
// }
.home {
  padding: 40px 0;
  width: 1200px;
  margin: 0 auto;
}

.bform {
  .el-form-item {
    width: 450px;
  }
}
</style>

<style lang="scss">
.el-tree-node__content {
  height: 40px !important;
}

.tree-line {
  .el-tree-node {
    position: relative;
    padding-left: 30px; // 缩进量
    margin-top: 10px;
  }

  .el-tree-node__children {
    padding-left: 30px; // 缩进量
  }

  // 竖线
  .el-tree-node::before {
    content: '';
    height: 100%;
    width: 1px;
    position: absolute;
    left: -3px;
    top: -15px;
    border-width: 1px;
    // dashed
    border-left: 1px solid #52627c;
  }

  // 当前层最后一个节点的竖线高度固定
  .el-tree-node:last-child::before {
    height: 45px; // 可以自己调节到合适数值
  }

  // 横线
  .el-tree-node::after {
    content: '';
    width: 20px;
    height: 40px;
    position: absolute;
    left: -3px;
    top: 30px;
    border-width: 1px;
    border-top: 1px solid #52627c;
  }

  // 去掉最顶层的虚线，放最下面样式才不会被上面的覆盖了
  &>.el-tree-node::after {
    border-top: none;
  }

  &>.el-tree-node::before {
    border-left: none;
  }

  // 展开关闭的icon
  .el-tree-node__expand-icon {
    font-size: 16px;

    // 叶子节点（无子节点）
    &.is-leaf {
      color: transparent;
      // display: none; // 也可以去掉
    }
  }
}
</style>

<style lang="scss" scoped>
.cateHome {
  min-height: 100vh;
  width: 90%;
  margin: 0 auto;

  padding-bottom: 150px;
}

.bform {
  .el-form-item {
    width: 450px;
  }
}

.flexRight {
  position: fixed;
  background: #1890ff;
  color: #fff;
  width: 180px;
  top: 14%;
  right: 1%;
  font-size: 12px;
  padding: 10px;
  box-sizing: border-box;
  border-radius: 12px;
  line-height: 20px;
  z-index: 9999;
}
</style>
